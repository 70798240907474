import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthenticationGuard } from "./guards/authenticated.guard";
import { OAuthCallbackComponent } from "./oauth-callback/oath-callback.component";
import { OAuthCallbackHandler } from "./guards/oauth-callback-guard";
import { LoginComponent } from "./login/login.component";
import { NotFoundComponent } from './not-found/not-found.component';
import { CrosswordPuzzleComponent } from './crossword-puzzle/crossword-puzzle.component';

//Route everything properly
const routes: Routes = [
  { path: "", component: CrosswordPuzzleComponent, pathMatch: "full" },
  { path: "404", component: NotFoundComponent},
  // {
  //   path: "instances/:value1/databases/:value2",
  //   component: DatabaseEditorComponent,
  //   //Redirects to auto login if user is not authenticated.
  //   //canActivate: [AuthenticationGuard]
  // },
  // {
  //   path: "instances/:value1/new",
  //   component: DatabaseEditorComponent,
  //   //Redirects to auto login if user is not authenticated.
  //   //canActivate: [AuthenticationGuard]
  // },
  {
    path: "id_token",
    component: OAuthCallbackComponent,
    canActivate: [OAuthCallbackHandler]
  },
  // { path: "login", component: LoginComponent },
  { path: "**", redirectTo: "404"},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
