import { Injectable } from "@angular/core";
import * as AuthenticationContext from "adal-angular";
import { environment } from "../../../environments/environment"

@Injectable()
export class AdalConfigService {
  config: AuthenticationContext.Options;
  notesObtained: boolean;
  status: string;
  error: any = null;
  IdToken: any;
  access: any;
  temp: string;

  constructor() {}
  //Set the config for the adal (adfs) configuration.
  public get adalConfig(): AuthenticationContext.Options {
    return {
      instance: environment.instance, //Set based on compilation parameter
      tenant: "adfs",
      clientId: environment.clientId, //Set based on compilation parameter
      cacheLocation: "localStorage",
      redirectUri: window.location.origin + '/id_token',
      postLogoutRedirectUri: window.location.origin + '/',
      //Standard call back for adal functions.
      callback: (error, token) => {
        console.log('acquireToken', error, token);
      }
    };
  }
}
