import * as AuthenticationContext from "adal-angular";
import { AdalConfigService } from "./adal-config.service";
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';

@Injectable()
//Service to handle all the adal interactions.
export class AdalService {
    authContext: AuthenticationContext;
    error: any = null;

    constructor(private adalConfig: AdalConfigService){
        this.authContext = new AuthenticationContext(this.adalConfig.adalConfig);
    }

    login(){
        this.authContext.login();
    }

    logout(){
        this.authContext.clearCache();
        this.authContext.logOut();
    }
    
    handleCallback(){
        this.authContext.handleWindowCallback();
    }

    public get userInfo() {
        return this.authContext.getCachedUser();
    }

    public getNewToken() {
        this.authContext.acquireTokenRedirect(this.authContext.config.clientId);
    }

    public get accessToken() {
        var user;
        var token = jwt_decode(this.authContext.getCachedToken(this.authContext.config.clientId));
        user = token['PUID'];
        if(this.isAuthenticated && user){
            return this.authContext.getCachedToken(this.authContext.config.clientId);
        }else{
            this.getNewToken()
            return this.authContext.getCachedToken(this.authContext.config.clientId);
        }
    }

    public get isAuthenticated(){
        return this.userInfo && this.authContext.getCachedToken(this.authContext.config.clientId);
    }
}