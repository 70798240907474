import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras } from '@angular/router';
import { AdalService } from "../services/adal/adal.service";
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationGuard implements CanActivate {
    constructor(private router: Router, private adalService: AdalService){
    }
    //Guard which stops unauthenticated access to parts of the website.
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let navigationExtras: NavigationExtras = {
            queryParams: { 'redirectUrl': route.url}
        };

        if (!this.adalService.isAuthenticated) {
            this.router.navigate(['login'], navigationExtras);
        }
        
        return true;
    }
}