import { Component, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { words } from '../models/words'
import { crossword } from '../models/crossword';
import { CrosswordApiService } from '../services/crossword/crossword-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-crossword-puzzle',
    templateUrl: './crossword-puzzle.component.html',
    styleUrls: ['./crossword-puzzle.component.css'],
})
export class CrosswordPuzzleComponent implements AfterViewInit {

    width: number;
    widthArr: number[];
    height: number;
    heightArr: number[];
    @ViewChild('crossword', { static: false }) crossword;
    highlightX;
    highlightY;
    blocks;
    direction;
    highlightWord: words;
    crosswordGame: crossword;
    words: words[];
    autocheck = false;
    debug = false;
    timer;
    hintClick = false;

    constructor(private crosswordService: CrosswordApiService, private _snackBar: MatSnackBar) {
        this.getCrosswordGame();
        this.timer = setInterval(this.tick, 1000);
    }

    @HostListener('window:keydown', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.key === 'Tab' || event.key === 'Backspace') {
            event.preventDefault();
        }
    }

    @HostListener('window:click', ['$event'])
    clickEvent(event: MouseEvent) {
        let temp = ((event.target as any).className as string);
        if (!(temp.includes('hint') || temp.includes('tile'))) {
            this.highlightWord = null;
            this.hintClick = false;

        }
    }

    @HostListener('window:blur', ['$event'])
    blurEvent(event){
        if(!this.debug){
            this.pause();
        }
    }

    removeHover() {
        if (this.hintClick != true) { this.highlightWord = null; }
        // this.hintClick = false;
    }

    addHover(word) {
        if (this.hintClick != true && this.highlightWord != word) {
            this.highlightWord = word;
            this.direction = word.direction;
        }
    }


    ngAfterViewInit() {
        this.blocks = this.crossword.nativeElement.children;
    }

    getCrosswordGame() {
        // this.crosswordService.getCrossword('global_quality_1', 'global_quality').subscribe(
        //     (crossword: crossword) => {
        //         this.crosswordGame = crossword;
        //         this.words = crossword.words;
        //     },
        //     error => {
        //         this._snackBar.open(
        //             'Error unable to get crossword:\n\n' + error + '.',
        //             'X',
        //             {
        //                 duration: 10000
        //             }
        //         );
        //     },
        //     () => {

        //         this.setWordDirections();
        //         this.calcEndPoints();

        //         let xMax = 0;
        //         let yMax = 0;
        //         this.direction = 'horizontal';

        //         for (let i = 0; i < this.words.length; i++) {
        //             if (this.words[i].end_cord_x > xMax) {
        //                 xMax = this.words[i].end_cord_x;
        //             }
        //             if (this.words[i].end_cord_y > yMax) {
        //                 yMax = this.words[i].end_cord_y;
        //             }
        //         }
        //         this.width = xMax + 1;
        //         this.height = yMax + 1;


        //         this.widthArr = Array(this.width).fill(0).map((x, i) => i);
        //         this.heightArr = Array(this.height).fill(0).map((x, i) => i);


        //         this.indexWords();

        // })
        this.crosswordGame = {
            game_name: "global_quality_1",
            theme: "global_quality",
            description: "global quality",
            words: [
                {
                    word: "MMY",
                    hint: "The site that doubled its output over the last 6 months with no quality incidents.",
                    start_cord_x: 13,
                    start_cord_y: 8,
                    end_cord_x: null,
                    end_cord_y: null,
                    direction: "horizontal"
                },
                {
                    word: "twelve",
                    hint: "How many manufacturing disciplines are there?",
                    start_cord_x: 10,
                    start_cord_y: 3,
                    end_cord_x: null,
                    end_cord_y: null,
                    direction: "horizontal"
                },
                {
                    word: "avalon",
                    hint: "What customer ranked Micron #1 for Quality for the past 2.5 years?",
                    start_cord_x: 3,
                    start_cord_y: 11,
                    end_cord_x: null,
                    end_cord_y: null,
                    direction: "vertical"
                },
                {
                    word: "shiftleft",
                    hint: "Two words. Mindset whereby we prevent quality issues as early as possible (e.g., from design stage).",
                    start_cord_x: 0,
                    start_cord_y: 8,
                    end_cord_x: null,
                    end_cord_y: null,
                    direction: "horizontal"
                },
                {
                    word: "variation",
                    hint: "A significant substrate issue surfaced in FY19 because of incoming material:",
                    start_cord_x: 2,
                    start_cord_y: 11,
                    end_cord_x: null,
                    end_cord_y: null,
                    direction: "horizontal"
                },
                {
                    word: "manufacturing",
                    hint: "Achieving higher CPK is a partnership between development and _________",
                    start_cord_x: 7,
                    start_cord_y: 4,
                    end_cord_x: null,
                    end_cord_y: null,
                    direction: "vertical"
                },
                {
                    word: "copyexact",
                    hint: "Two words; A best practice when starting a new site.",
                    start_cord_x: 15,
                    start_cord_y: 5,
                    end_cord_x: null,
                    end_cord_y: null,
                    direction: "vertical"
                },
                {
                    word: "quality",
                    hint: "The focus of the round table.",
                    start_cord_x: 13,
                    start_cord_y: 0,
                    end_cord_x: null,
                    end_cord_y: null,
                    direction: "vertical"
                },
                {
                    word: "lvm",
                    hint: "Acronym; A key component of the shift left mindset in package development is ensuring all problems have been resolved by the time a product leaves ___",
                    start_cord_x: 3,
                    start_cord_y: 14,
                    end_cord_x: null,
                    end_cord_y: null,
                    direction: "horizontal"
                },
                {
                    word: "dataanalytics",
                    hint: "Two words; Engineers - especially those on the production line - need this to be accurate and timely. An area of focus to take all aspects of our business to the next level.",
                    start_cord_x: 4,
                    start_cord_y: 5,
                    end_cord_x: null,
                    end_cord_y: null,
                    direction: "horizontal"
                }
            ]
        }
        this.words = this.crosswordGame.words;

        this.setWordDirections();
                this.calcEndPoints();

                let xMax = 0;
                let yMax = 0;
                this.direction = 'horizontal';

                for (let i = 0; i < this.words.length; i++) {
                    if (this.words[i].end_cord_x > xMax) {
                        xMax = this.words[i].end_cord_x;
                    }
                    if (this.words[i].end_cord_y > yMax) {
                        yMax = this.words[i].end_cord_y;
                    }
                }
                this.width = xMax + 1;
                this.height = yMax + 1;


                this.widthArr = Array(this.width).fill(0).map((x, i) => i);
                this.heightArr = Array(this.height).fill(0).map((x, i) => i);


                this.indexWords();
    }

    calcEndPoints() {
        for (let i = 0; i < this.words.length; i++) {
            if (this.words[i].direction == 'horizontal') {
                this.words[i].end_cord_x = this.words[i].start_cord_x + this.words[i].word.length - 1;
                this.words[i].end_cord_y = this.words[i].start_cord_y;
            }
            else if (this.words[i].direction == 'vertical') {
                this.words[i].end_cord_x = this.words[i].start_cord_x;
                this.words[i].end_cord_y = this.words[i].start_cord_y + this.words[i].word.length - 1;
            }
        }
    }

    setWordDirections() {
        for (let i = 0; i < this.words.length; i++) {
            let word = this.words[i];
            if (word.start_cord_y == word.end_cord_y) {
                word.direction = 'horizontal';
            }
            else if (word.start_cord_x == word.end_cord_x) {
                word.direction = 'vertical';
            }
        }
    }

    indexWords() {
        let index = 1;
        for (let y = 0; y < this.height; y++) {
            for (let x = 0; x < this.width; x++) {
                if (this.isStart(x, y)) {
                    let targetWords = this.getWords(x, y);
                    for (let i = 0; i < targetWords.length; i++) {
                        targetWords[i].index = index;
                    }
                    index++;
                }
            }
        }
        this.words.sort(this.sortWordByIndex);
    }

    getTile(x, y) {
        let index = this.getBlockIndex(x, y);
        let block = this.blocks[index];
        if (block != undefined) {
            let tiles = block.getElementsByClassName('tile');
            return tiles[0];
        }
    }

    getBlockIndex(x, y) {
        return (y * this.width) + x;
    }

    isStart(x, y) {
        for (let i = 0; i < this.words.length; i++) {
            let cword = this.words[i];
            if (cword.start_cord_x == x && cword.start_cord_y == y) {
                return true;
            }
        }
        return false;
    }

    getWords(x, y) {
        let targetWords = [];
        for (let i = 0; i < this.words.length; i++) {
            let word = this.words[i];
            if (x >= word.start_cord_x && x <= word.end_cord_x && y >= word.start_cord_y && y <= word.end_cord_y) {
                if(x == 3 && y == 11 && word.word == "variation"){
                    continue;
                }
                if(x == 3 && y == 14 && word.word == "avalon"){
                    continue;
                }
                if( x == 4 && y == 5 && word.word == "copyexact"){
                    continue;
                }
                
                if( x == 15 && y == 5 && word.word == "dataanalytics"){
                    continue;
                }
                targetWords.push(word);
            }
        }

        // if(targetWords[0].word == "variation" && targetWords[1].word == "avalon"){
        //     targetWords[1].index = 1;
        // }

        return targetWords;
    }

    sortWordByIndex(a, b) {
        if (a.index > b.index) {
            return 1;
        }
        else if (a.index == b.index) {
            return 0;
        }
        return -1;
    }

    isTile(x, y) {
        for (let i = 0; i < this.words.length; i++) {
            let cword = this.words[i];
            if (x >= cword.start_cord_x && x <= cword.end_cord_x && y >= cword.start_cord_y && y <= cword.end_cord_y) {
                return true;
            }
        }
        return false;
    }

    isHighlighted(x, y) {
        if (this.highlightWord == null) {
            return false;
        }
        if (this.direction == 'horizontal' && this.isTile(x, y)
            && y == this.highlightWord.start_cord_y
            && x >= this.highlightWord.start_cord_x
            && x <= this.highlightWord.word.length + this.highlightWord.start_cord_x) {
            return true;
        } else if (this.direction == 'vertical' && this.isTile(x, y)
            && x == this.highlightWord.start_cord_x
            && y >= this.highlightWord.start_cord_y
            && y <= this.highlightWord.word.length + this.highlightWord.start_cord_y) {
            return true;
        } else {
            return false;
        }
    }

    highlightTiles(x, y, targetWords: words[]) {
        this.highlightX = x;
        this.highlightY = y;
        if (targetWords[0].direction == this.direction) {
            this.highlightWord = targetWords[0];
        } else {
            this.highlightWord = targetWords[1];
        }
    }

    next(tile, x, y) {
        var next;
        if(this.direction == "horizontal") {
            next = this.getTile(x + 1, y);
        }
        else if(this.direction == "vertical") {
            next = this.getTile(x, y + 1);
        }

        if(next != undefined) {
            next.select();
        }
    }

    input(event, tile, x, y) {
        if(event.inputType == "insertText") {
            this.next(tile, x, y);
        }
        this.checkDone();
    }

    arrowUp(tile, xStr, yStr) {
        let x = parseInt(xStr);
        let y = parseInt(yStr);
        if (this.isTile(x, y - 1)) {
            tile = this.getTile(x, y - 1);
        }
        if (tile != undefined) {
            tile.select();
        }
    }

    arrowDown(tile, xStr, yStr) {
        let x = parseInt(xStr);
        let y = parseInt(yStr);
        if (this.isTile(x, y + 1)) {
            tile = this.getTile(x, y + 1);
        }
        if (tile != undefined) {
            tile.select();
        }
    }

    arrowLeft(tile, xStr, yStr) {
        let x = parseInt(xStr);
        let y = parseInt(yStr);
        if (this.isTile(x - 1, y)) {
            tile = this.getTile(x - 1, y);
        }
        if (tile != undefined) {
            tile.select();
        }
    }

    arrowRight(tile, xStr, yStr) {
        let x = parseInt(xStr);
        let y = parseInt(yStr);
        if (this.isTile(x + 1, y)) {
            tile = this.getTile(x + 1, y);
        }
        if (tile != undefined) {
            tile.select();
        }
    }

    tab(xStr, yStr) {
        let x = parseInt(xStr);
        let y = parseInt(yStr);
        let tile;
        if (this.direction == 'horizontal') {
            tile = this.getTile(x + 1, y);
        }
        else if (this.direction == 'vertical') {
            tile = this.getTile(x, y + 1);
        }
        if (tile != undefined) {
            tile.select();
        }
    }

    backspace(tile, xStr, yStr) {
        let x = parseInt(xStr);
        let y = parseInt(yStr);
        if (tile.value == '') {
            if (this.direction == 'horizontal' && this.isTile(x - 1, y)) {
                tile = this.getTile(x - 1, y);
            }
            else if (this.direction == 'vertical' && this.isTile(x, y - 1)) {
                tile = this.getTile(x, y - 1);
            }
        } else {
            tile.value = '';
        }
        if (tile != undefined) {
            tile.select();
        }
    }

    focus(tile, xStr, yStr) {
        let x = parseInt(xStr);
        let y = parseInt(yStr);

        let targetWords = this.getWords(x, y);

        let above = this.isTile(x, y - 1);
        let below = this.isTile(x, y + 1);
        let left = this.isTile(x - 1, y);
        let right = this.isTile(x + 1, y);

        tile.select();

        if ((above || below) && !(left || right)) {
            this.direction = 'vertical';
        }
        else if (!(above || below) && (left || right)) {
            this.direction = 'horizontal';
        }

        this.highlightTiles(x, y, targetWords);
    }

    click(xStr, yStr) {
        let x = parseInt(xStr);
        let y = parseInt(yStr);

        let targetWords = this.getWords(x, y);

        let above = this.isTile(x, y - 1);
        let below = this.isTile(x, y + 1);
        let left = this.isTile(x - 1, y);
        let right = this.isTile(x + 1, y);

        if ((above || below) && (left || right)) {
            if (this.direction == 'horizontal') {
                this.direction = 'vertical';
            }
            else if (this.direction == 'vertical') {
                this.direction = 'horizontal';
            }
        }

        this.highlightTiles(x, y, targetWords);
    }

    check(tile, x, y) {

        let targetWords = this.getWords(x, y);
        let targetWord = targetWords[0];

        let index;
        if (targetWord.direction == 'horizontal') {
            index = x - targetWord.start_cord_x;
        }
        else if (targetWord.direction == 'vertical') {
            index = y - targetWord.start_cord_y;
        }
        let correct = targetWord.word.charAt(index);
        if (tile.value.toLowerCase() == correct.toLowerCase()) {
            return true;
        }
        else if (tile.value == '') {
            return null;
        }
        else {
            return false;
        }
    }

    checkAll() {
        let tiles = this.crossword.nativeElement.getElementsByClassName('tile');
        for (let i = 0; i < tiles.length; i++) {
            // this.check(tiles[i]);
        }
    }

    checkClear() {
        let tiles = this.crossword.nativeElement.getElementsByClassName('tile');
        for (let i = 0; i < tiles.length; i++) {
            if (tiles[i].classList.contains('wrong')) {
                tiles[i].classList.remove('wrong');
            }
            if (tiles[i].classList.contains('correct')) {
                tiles[i].classList.remove('correct');
            }
        }
    }

    checkDone() {
        let tiles = this.crossword.nativeElement.getElementsByClassName('tile');
        let ret = true;
        for (let i = 0; i < tiles.length; i++) {
            console.log('I: ' + i);
            // this.check(tiles[i]);
            if (tiles[i].classList.contains('wrong') || tiles[i].value == '') {
                ret = false;
                break;
            }
        }
        if (!this.autocheck) {
            this.checkClear();
        }
        if (ret) {
            this.done();
        }
        return ret;
    }

    clearIncorrect() {
        let tiles = this.crossword.nativeElement.getElementsByClassName('tile');
        if (!this.autocheck) {
            this.checkAll();
        }
        for (let i = 0; i < tiles.length; i++) {
            if (tiles[i].classList.contains('wrong')) {
                tiles[i].value = '';
                tiles[i].classList.remove('wrong');
            }
            if (!this.autocheck) {
                if (tiles[i].classList.contains('correct')) {
                    tiles[i].classList.remove('correct');
                }
            }
        }
    }

    clearPuzzle() {
        let tiles = this.crossword.nativeElement.getElementsByClassName('tile');
        for (let i = 0; i < tiles.length; i++) {
            tiles[i].value = '';
            if (tiles[i].classList.contains('correct')) {
                tiles[i].classList.remove('correct');
            }
            if (tiles[i].classList.contains('wrong')) {
                tiles[i].classList.remove('wrong');
            }
        }
        this.clearPuzzleModalCancel();
    }

    clearPuzzleModal() {
        let modal = document.getElementById('modal-clear-confirm');
        let resumeBtn = document.getElementById('resume');
        modal.classList.add('active');
        resumeBtn.classList.add('hide');
        this.pause();
    }

    clearPuzzleModalCancel() {
        let modal = document.getElementById('modal-clear-confirm');
        let resumeBtn = document.getElementById('resume');
        modal.classList.remove('active');
        resumeBtn.classList.remove('hide');
        this.resume(); this.timer = setInterval(this.tick, 1000);
    }

    done() {
        let modal = document.getElementById('modal-done');
        let btnResume = document.getElementById('resume');
        modal.classList.add('active');
        btnResume.classList.add('hide');
        this.pause();
    }

    pause() {
        let controls = document.getElementById('controls');
        let main = document.getElementById('main');
        let overlay = document.getElementById('overlay');

        controls.classList.add('blur');
        main.classList.add('blur');
        overlay.classList.add('active');

        window.clearInterval(this.timer);
    }

    resume() {
        let controls = document.getElementById('controls');
        let main = document.getElementById('main');
        let overlay = document.getElementById('overlay');

        controls.classList.remove('blur');
        main.classList.remove('blur');
        overlay.classList.remove('active');

        this.timer = setInterval(this.tick, 1000);
    }

    revealPuzzleModal() {
        let modal = document.getElementById('modal-reveal-confirm');
        let resumeBtn = document.getElementById('resume');
        modal.classList.add('active');
        resumeBtn.classList.add('hide');
        this.pause();
    }

    revealPuzzleModalCancel() {
        let modal = document.getElementById('modal-reveal-confirm');
        let resumeBtn = document.getElementById('resume');
        modal.classList.remove('active');
        resumeBtn.classList.remove('hide');
        this.resume();
    }

    tick() {
        let minutes = document.getElementById('timer-minutes');
        let seconds = document.getElementById('timer-seconds');
        let sval = parseInt(seconds.innerHTML);
        let mval = parseInt(minutes.innerHTML);

        sval++;
        if (sval < 10) {
            seconds.innerHTML = '0' + sval;
        }
        else {
            seconds.innerHTML = sval.toString();
        }

        if (sval >= 60) {
            seconds.innerHTML = '00';
            mval++;
            if (mval < 10) {
                minutes.innerHTML = '0' + mval;
            }
            else {
                minutes.innerHTML = mval.toString();
            }
        }
    }

    toggleAutocheck() {
        let chip = document.getElementById('chip');
        let val = chip.innerHTML;
        if (val == 'off') {
            chip.classList.remove('off');
            chip.classList.add('on');
            chip.innerHTML = 'on';
            this.autocheck = true;
            // this.checkAll();
        }
        else if (val == 'on') {
            chip.classList.remove('on');
            chip.classList.add('off');
            chip.innerHTML = 'off';
            this.autocheck = false;
            // this.checkClear();
        }
    }

    wordnum_click(block) {
        let tile = block.getElementsByClassName('tile');
        tile[0].select();
    }

}
