import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { crossword } from 'src/app/models/crossword';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrosswordApiService {
  apiURL = 'http://localhost:8080/api/crossword'
  constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute) { }

    getCrossword(gameName: string, theme: string){
        return this.http.get<crossword>(this.apiURL+'/'+gameName+'/'+theme)
        .pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse){
        var error_message = '';
        if (error.error instanceof ErrorEvent){
            console.error('An error occured:', error.error.message);
            error_message += error.error.message;
        }else{
            console.error(
                `Backend returned code ${error.status},`+`body was: ${error.message}`); 
                error_message += "[ " + error.message + " ]";
                if(error.status === 404){
                    error_message += "\n\nThis crossword does not exist!"
                }
            
        }
        return throwError(error_message);
    }
}
