import { OnInit, Component } from "@angular/core";
import { Router,  ActivatedRoute } from '@angular/router';
import { AdalService } from '../services/adal/adal.service';
import * as jwt_decode from 'jwt-decode';

@Component({
    templateUrl: './login.component.html'
})
//Auto logins the user when redirect to the page, has button for logging out as well.
export class LoginComponent implements OnInit {
    constructor(private router:Router, private adalService: AdalService, private route:ActivatedRoute) {
        //Get redirect url for return to where we were when we were redirect to the login page.
        var returnUrl = this.route.snapshot.queryParams['redirectUrl'];
        ////Login or return if already logged in.
        // if(!(this.adalService.isAuthenticated)){
        //     this.login();
        // }else if (returnUrl){
        //     this.router.navigate(returnUrl)
        // }
        
    }
    //Print some useful info.
    ngOnInit(){
        console.log(this.adalService.userInfo);
        console.log(this.adalService.accessToken);
        console.log(jwt_decode(this.adalService.accessToken));
        var token = jwt_decode(this.adalService.accessToken);
        console.log(token['PUID']);
        
    }

    login(){
        this.adalService.login();
    }
    
    logout() {
        this.adalService.logout();
    }

    public get isLoggedIn(){
        return this.adalService.isAuthenticated;
    }
}