import { Component, OnInit } from '@angular/core';
import { AdalConfigService } from './services/adal/adal-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [AdalConfigService]
})
export class AppComponent {
  constructor(private adalConfig: AdalConfigService) {}
  title = 'Global Quality Crossword';
}
