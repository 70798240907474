import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AdalService } from '../services/adal/adal.service';

@Injectable()
export class OAuthCallbackHandler implements CanActivate {
    temp: any
    constructor( private router: Router, private adalService: AdalService){

    }
    //Handles the redirect link that the adfs server sends.
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        
        this.adalService.handleCallback();
        //If the authentication didn't work redirect to login.
        if(!(this.adalService.userInfo)) {
            this.router.navigate(['login']);
        }

        return false;
    }
}