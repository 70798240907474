import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./material-module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { OAuthHandshakeModule } from "./oauth-callback/oauth-callback.module";
import { LoginComponent } from "./login/login.component";
import { httpInterceptor } from "./interceptors/httpInterceptor";
import { OAuthCallbackHandler } from "./guards/oauth-callback-guard";
import { AuthenticationGuard } from "./guards/authenticated.guard";
import { AdalService } from "./services/adal/adal.service";
import { AdalConfigService } from "./services/adal/adal-config.service";
import { NotFoundComponent } from './not-found/not-found.component';
import { CrosswordPuzzleComponent } from './crossword-puzzle/crossword-puzzle.component';


@NgModule({
  declarations: [AppComponent, LoginComponent, NotFoundComponent, CrosswordPuzzleComponent],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    OAuthHandshakeModule
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: httpInterceptor, multi: true },
    OAuthCallbackHandler,
    AuthenticationGuard,
    AdalService,
    AdalConfigService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
