import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AdalService } from "../services/adal/adal.service";

@Component({
  template: "<p><div>Please waits...</div><p>"
})
//Navigate to the login page or to home page.
export class OAuthCallbackComponent implements OnInit {
  constructor(private router: Router, private adalService: AdalService) {}

  ngOnInit() {
    if (!this.adalService.userInfo) {
      this.router.navigate(["login"]);
    } else {
      this.router.navigate([""]);
    }
  }
}
