import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AdalService } from "../services/adal/adal.service";

@Injectable()
// Intercepts and injects the auth header into outgoing http requests.
export class httpInterceptor implements HttpInterceptor {
  constructor(private adalService: AdalService) {}

  intercept(
    request: HttpRequest<any>,
    newRequest: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header to request

    //Get Token data from local storage
    // let tokenInfo = this.adalService.accessToken;

    // if (tokenInfo && tokenInfo) {
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${tokenInfo}`
    //     }
    //   });
    // }

    return newRequest.handle(request);
  }
}
