import { NgModule } from "@angular/core";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
//Import and Export all the material ui elements used.
@NgModule({
  exports: [
      MatInputModule,
      MatButtonModule,
      MatSnackBarModule,
      MatFormFieldModule,
      MatProgressBarModule,
      MatListModule
    ]
})
export class MaterialModule {}
