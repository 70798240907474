import { NgModule } from "@angular/core";
import { OAuthCallbackComponent } from "./oath-callback.component";
import { OAuthCallbackHandler } from "../guards/oauth-callback-guard";

@NgModule({
  imports: [],
  declarations: [OAuthCallbackComponent],
  providers: [OAuthCallbackHandler]
})
export class OAuthHandshakeModule {}
